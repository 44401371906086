










































































































import { Component, Vue, Watch } from "vue-property-decorator";

interface Tipos {
  value: number | any;
  label: string;
}

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {}
})
export default class Elecciones extends Vue {
  private opcion: number | any;
  private currentPage = 1;
  private total = 0;
  private data = false;
  private aElecciones: any = [];
  private aDirectivas: any = [];
  private directiva: any = [];
  private orgOptions: SelectOption[] = [];

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 20)) {
      this.getElecciones();
    }
  }

  private mounted() {
    this.getElecciones();
  }

  private getElecciones() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("elecciones?pagina=" + this.currentPage)
      .then(res => {
        this.aElecciones = res.data.elecciones;

        for (let i = 0; i < this.aElecciones.length; i++) {
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Âº",
            "°"
          );
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Ã‘",
            "Ñ"
          );
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Ã³",
            "ó"
          );
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Í“",
            "Ó"
          );
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Ã‰",
            "É"
          );
          this.aElecciones[i].lugar = this.aElecciones[i].lugar.replaceAll(
            "Ã",
            "Í"
          );
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Ã‘", "Ñ");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Ã“", "Ó");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Ã‹", "Ë");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Ã‰", "É");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Ã", "Í");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Í±", "ñ");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Â°", "°");
          this.aElecciones[i].nombre_orco = this.aElecciones[
            i
          ].nombre_orco.replaceAll("Âº", "°");
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private mostrarConvocatoria(link: any) {
    let base = process.env.VUE_APP_BASE_URL;
    window.open(base + link, "_blank");
  }

  private formatDate(date: any) {
    // 2019-09-25T19:04:57.000Z to 25-09-2020 19:04
    var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(8, 10),
      hours = date.substring(11, 13),
      minutes = date.substring(14, 16);
    return [day, month, year].join("-");
  }

  private formatHora(date: any) {
    // 2019-09-25T19:04:57.000Z to 25-09-2020 19:04
    var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(8, 10),
      hours = date.substring(11, 13),
      minutes = date.substring(14, 16);
    return [hours, minutes].join(":");
  }
}
