








import { Component, Vue } from "vue-property-decorator";
import Elecciones from "@/components/Comuna/Elecciones.vue";

@Component({
  components: {
    Elecciones
  }
})
export default class EleccionesView extends Vue {}
