
































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

interface Tipos {
  value: number | any;
  label: string;
}

interface SelectOption {
  value: number | any;
  label: string;
}

@Component({
  components: {}
})
export default class OrganizacionesComunitarias extends Vue {
  private opcion: number | any = 1;
  private currentPage = 1;
  private total = 0;
  private data = false;
  private aOrganizaciones: any = [];
  private aDirectivas: any = [];
  private directiva: any = [];
  private dataOrganizaciones: Tipos = {
    value: 1,
    label: "Organizaciones Territoriales"
  };

  private orgOptions: SelectOption[] = [];

  @Watch("dataOrganizaciones")
  private orgWatcher(newValue: any) {
    this.opcion = newValue.value;
    this.currentPage = 1;
    this.getOrganizaciones();
  }

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 20)) {
      this.getOrganizaciones();
    }
  }

  private mounted() {
    this.getOrganizaciones();
    this.orgOptions.push(
      { value: 1, label: "Organizaciones Territoriales" },
      { value: 2, label: "Organizaciones Funcionales" },
      { value: 3, label: "Uniones Comunales" }
    );
  }

  private getOrganizaciones() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("organizaciones?tipo=" + this.opcion + "&pagina=" + this.currentPage)
      .then(res => {
        this.aOrganizaciones = res.data.organizaciones;
        for (let i = 0; i < this.aOrganizaciones.length; i++) {
          this.aOrganizaciones[i].nombre_org = this.aOrganizaciones[
            i
          ].nombre_org.replaceAll("&deg;", "°");
          this.aOrganizaciones[i].nombre_org = this.aOrganizaciones[
            i
          ].nombre_org.replaceAll("&Ntilde;", "Ñ");
          this.aOrganizaciones[i].direccion_org = this.aOrganizaciones[
            i
          ].direccion_org.replaceAll("&deg;", "°");
          this.aOrganizaciones[i].direccion_org = this.aOrganizaciones[
            i
          ].direccion_org.replaceAll("&Ntilde;", "Ñ");
        }
        this.aDirectivas = res.data.directiva;
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
      });
  }

  private obtenerDirectiva(id: any) {
    for (let i = 0; i < this.aDirectivas.length; i++) {
      this.aDirectivas[i].nombre_org = this.aDirectivas[
        i
      ].nombre_org.replaceAll("&deg;", "°");
      this.aDirectivas[i].nombre_org = this.aDirectivas[
        i
      ].nombre_org.replaceAll("&Ntilde;", "Ñ");
      if (this.aDirectivas[i].id_org == id) {
        this.directiva.push({
          fecha_vigencia: this.aDirectivas[i].fecha_vigencia,
          nombre: this.aDirectivas[i].nombre,
          tipo: this.aDirectivas[i].tipo,
          nombre_org: this.aDirectivas[i].nombre_org
        });
      }
    }
    this.data = true;
  }

  private cerrarData() {
    this.data = false;
    this.directiva = [];
  }

  private formatDate(date: any) {
    // 2019-09-25T19:04:57.000Z to 25-09-2020 19:04
    var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(8, 10),
      hours = date.substring(11, 13),
      minutes = date.substring(14, 16);
    return [day, month, year].join("-");
  }
}
