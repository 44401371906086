








import { Component, Vue } from "vue-property-decorator";
import OrganizacionesComunitarias from "@/components/Comuna/OrganizacionesComunitarias.vue";

@Component({
  components: {
    OrganizacionesComunitarias
  }
})
export default class OrganizacionesComunitariasView extends Vue {}
