

























































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class RegistroPublico extends Vue {
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
