








import { Component, Vue } from "vue-property-decorator";
import RegistroPublico from "@/components/Comuna/RegistroPublico.vue";

@Component({
  components: {
    RegistroPublico
  }
})
export default class RegistroPublicoView extends Vue {}
